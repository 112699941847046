/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementOutgoingAccessRequest } from './ManagementOutgoingAccessRequest';
import {
    ManagementOutgoingAccessRequestFromJSON,
    ManagementOutgoingAccessRequestFromJSONTyped,
    ManagementOutgoingAccessRequestToJSON,
} from './ManagementOutgoingAccessRequest';

/**
 * 
 * @export
 * @interface ManagementSendAccessRequestResponse
 */
export interface ManagementSendAccessRequestResponse {
    /**
     * 
     * @type {ManagementOutgoingAccessRequest}
     * @memberof ManagementSendAccessRequestResponse
     */
    outgoingAccessRequest?: ManagementOutgoingAccessRequest;
}

/**
 * Check if a given object implements the ManagementSendAccessRequestResponse interface.
 */
export function instanceOfManagementSendAccessRequestResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementSendAccessRequestResponseFromJSON(json: any): ManagementSendAccessRequestResponse {
    return ManagementSendAccessRequestResponseFromJSONTyped(json, false);
}

export function ManagementSendAccessRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementSendAccessRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outgoingAccessRequest': !exists(json, 'outgoing_access_request') ? undefined : ManagementOutgoingAccessRequestFromJSON(json['outgoing_access_request']),
    };
}

export function ManagementSendAccessRequestResponseToJSON(value?: ManagementSendAccessRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outgoing_access_request': ManagementOutgoingAccessRequestToJSON(value.outgoingAccessRequest),
    };
}

