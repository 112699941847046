/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementRegisterOutwayRequest
 */
export interface ManagementRegisterOutwayRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagementRegisterOutwayRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementRegisterOutwayRequest
     */
    publicKeyPem?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementRegisterOutwayRequest
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementRegisterOutwayRequest
     */
    selfAddressApi?: string;
}

/**
 * Check if a given object implements the ManagementRegisterOutwayRequest interface.
 */
export function instanceOfManagementRegisterOutwayRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementRegisterOutwayRequestFromJSON(json: any): ManagementRegisterOutwayRequest {
    return ManagementRegisterOutwayRequestFromJSONTyped(json, false);
}

export function ManagementRegisterOutwayRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementRegisterOutwayRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'publicKeyPem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'selfAddressApi': !exists(json, 'self_address_api') ? undefined : json['self_address_api'],
    };
}

export function ManagementRegisterOutwayRequestToJSON(value?: ManagementRegisterOutwayRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'public_key_pem': value.publicKeyPem,
        'version': value.version,
        'self_address_api': value.selfAddressApi,
    };
}

