/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementInway } from './ManagementInway';
import {
    ManagementInwayFromJSON,
    ManagementInwayFromJSONTyped,
    ManagementInwayToJSON,
} from './ManagementInway';

/**
 * 
 * @export
 * @interface ManagementListInwaysResponse
 */
export interface ManagementListInwaysResponse {
    /**
     * 
     * @type {Array<ManagementInway>}
     * @memberof ManagementListInwaysResponse
     */
    inways?: Array<ManagementInway>;
}

/**
 * Check if a given object implements the ManagementListInwaysResponse interface.
 */
export function instanceOfManagementListInwaysResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementListInwaysResponseFromJSON(json: any): ManagementListInwaysResponse {
    return ManagementListInwaysResponseFromJSONTyped(json, false);
}

export function ManagementListInwaysResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementListInwaysResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inways': !exists(json, 'inways') ? undefined : ((json['inways'] as Array<any>).map(ManagementInwayFromJSON)),
    };
}

export function ManagementListInwaysResponseToJSON(value?: ManagementListInwaysResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inways': value.inways === undefined ? undefined : ((value.inways as Array<any>).map(ManagementInwayToJSON)),
    };
}

