/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementDirectoryNlxService } from './ManagementDirectoryNlxService';
import {
    ManagementDirectoryNlxServiceFromJSON,
    ManagementDirectoryNlxServiceFromJSONTyped,
    ManagementDirectoryNlxServiceToJSON,
} from './ManagementDirectoryNlxService';

/**
 * 
 * @export
 * @interface ManagementGetOrganizationServiceResponse
 */
export interface ManagementGetOrganizationServiceResponse {
    /**
     * 
     * @type {ManagementDirectoryNlxService}
     * @memberof ManagementGetOrganizationServiceResponse
     */
    directoryService?: ManagementDirectoryNlxService;
}

/**
 * Check if a given object implements the ManagementGetOrganizationServiceResponse interface.
 */
export function instanceOfManagementGetOrganizationServiceResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementGetOrganizationServiceResponseFromJSON(json: any): ManagementGetOrganizationServiceResponse {
    return ManagementGetOrganizationServiceResponseFromJSONTyped(json, false);
}

export function ManagementGetOrganizationServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementGetOrganizationServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'directoryService': !exists(json, 'directory_service') ? undefined : ManagementDirectoryNlxServiceFromJSON(json['directory_service']),
    };
}

export function ManagementGetOrganizationServiceResponseToJSON(value?: ManagementGetOrganizationServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'directory_service': ManagementDirectoryNlxServiceToJSON(value.directoryService),
    };
}

