// Copyright © VNG Realisatie 2020
// Licensed under the EUPL
//
const serviceActions = {
  ADDED: 'added',
  EDITED: 'edited',
  REMOVED: 'removed',
}

export default serviceActions
