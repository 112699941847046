/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManagementCreateOutgoingOrderRequest,
  ManagementCreateServiceRequest,
  ManagementCreateServiceResponse,
  ManagementDownloadFinanceExportResponse,
  ManagementGetInwayResponse,
  ManagementGetServiceResponse,
  ManagementGetSettingsResponse,
  ManagementGetStatisticsOfServicesResponse,
  ManagementGetTermsOfServiceStatusResponse,
  ManagementInway,
  ManagementIsFinanceEnabledResponse,
  ManagementIsTXLogEnabledResponse,
  ManagementListAccessGrantsForServiceResponse,
  ManagementListAuditLogsResponse,
  ManagementListIncomingAccessRequestsResponse,
  ManagementListIncomingOrdersResponse,
  ManagementListInwaysResponse,
  ManagementListOutgoingOrdersResponse,
  ManagementListOutwaysResponse,
  ManagementListServicesResponse,
  ManagementRegisterInwayRequest,
  ManagementRegisterInwayResponse,
  ManagementRegisterOutwayRequest,
  ManagementRevokeAccessGrantResponse,
  ManagementSendAccessRequestResponse,
  ManagementServiceUpdateServiceRequest,
  ManagementSynchronizeOrdersResponse,
  ManagementUpdateInwayResponse,
  ManagementUpdateOutgoingOrderRequest,
  ManagementUpdateServiceResponse,
  ManagementUpdateSettingsRequest,
  RpcStatus,
} from '../models';
import {
    ManagementCreateOutgoingOrderRequestFromJSON,
    ManagementCreateOutgoingOrderRequestToJSON,
    ManagementCreateServiceRequestFromJSON,
    ManagementCreateServiceRequestToJSON,
    ManagementCreateServiceResponseFromJSON,
    ManagementCreateServiceResponseToJSON,
    ManagementDownloadFinanceExportResponseFromJSON,
    ManagementDownloadFinanceExportResponseToJSON,
    ManagementGetInwayResponseFromJSON,
    ManagementGetInwayResponseToJSON,
    ManagementGetServiceResponseFromJSON,
    ManagementGetServiceResponseToJSON,
    ManagementGetSettingsResponseFromJSON,
    ManagementGetSettingsResponseToJSON,
    ManagementGetStatisticsOfServicesResponseFromJSON,
    ManagementGetStatisticsOfServicesResponseToJSON,
    ManagementGetTermsOfServiceStatusResponseFromJSON,
    ManagementGetTermsOfServiceStatusResponseToJSON,
    ManagementInwayFromJSON,
    ManagementInwayToJSON,
    ManagementIsFinanceEnabledResponseFromJSON,
    ManagementIsFinanceEnabledResponseToJSON,
    ManagementIsTXLogEnabledResponseFromJSON,
    ManagementIsTXLogEnabledResponseToJSON,
    ManagementListAccessGrantsForServiceResponseFromJSON,
    ManagementListAccessGrantsForServiceResponseToJSON,
    ManagementListAuditLogsResponseFromJSON,
    ManagementListAuditLogsResponseToJSON,
    ManagementListIncomingAccessRequestsResponseFromJSON,
    ManagementListIncomingAccessRequestsResponseToJSON,
    ManagementListIncomingOrdersResponseFromJSON,
    ManagementListIncomingOrdersResponseToJSON,
    ManagementListInwaysResponseFromJSON,
    ManagementListInwaysResponseToJSON,
    ManagementListOutgoingOrdersResponseFromJSON,
    ManagementListOutgoingOrdersResponseToJSON,
    ManagementListOutwaysResponseFromJSON,
    ManagementListOutwaysResponseToJSON,
    ManagementListServicesResponseFromJSON,
    ManagementListServicesResponseToJSON,
    ManagementRegisterInwayRequestFromJSON,
    ManagementRegisterInwayRequestToJSON,
    ManagementRegisterInwayResponseFromJSON,
    ManagementRegisterInwayResponseToJSON,
    ManagementRegisterOutwayRequestFromJSON,
    ManagementRegisterOutwayRequestToJSON,
    ManagementRevokeAccessGrantResponseFromJSON,
    ManagementRevokeAccessGrantResponseToJSON,
    ManagementSendAccessRequestResponseFromJSON,
    ManagementSendAccessRequestResponseToJSON,
    ManagementServiceUpdateServiceRequestFromJSON,
    ManagementServiceUpdateServiceRequestToJSON,
    ManagementSynchronizeOrdersResponseFromJSON,
    ManagementSynchronizeOrdersResponseToJSON,
    ManagementUpdateInwayResponseFromJSON,
    ManagementUpdateInwayResponseToJSON,
    ManagementUpdateOutgoingOrderRequestFromJSON,
    ManagementUpdateOutgoingOrderRequestToJSON,
    ManagementUpdateServiceResponseFromJSON,
    ManagementUpdateServiceResponseToJSON,
    ManagementUpdateSettingsRequestFromJSON,
    ManagementUpdateSettingsRequestToJSON,
    RpcStatusFromJSON,
    RpcStatusToJSON,
} from '../models';

export interface ManagementServiceApproveIncomingAccessRequestRequest {
    serviceName: string;
    accessRequestId: string;
}

export interface ManagementServiceCreateOutgoingOrderRequest {
    body: ManagementCreateOutgoingOrderRequest;
}

export interface ManagementServiceCreateServiceRequest {
    body: ManagementCreateServiceRequest;
}

export interface ManagementServiceDeleteInwayRequest {
    name: string;
}

export interface ManagementServiceDeleteOutwayRequest {
    name: string;
}

export interface ManagementServiceDeleteServiceRequest {
    name: string;
}

export interface ManagementServiceGetInwayRequest {
    name: string;
}

export interface ManagementServiceGetServiceRequest {
    name: string;
}

export interface ManagementServiceListAccessGrantsForServiceRequest {
    serviceName: string;
}

export interface ManagementServiceListIncomingAccessRequestsRequest {
    serviceName: string;
}

export interface ManagementServiceRegisterInwayRequest {
    body: ManagementRegisterInwayRequest;
}

export interface ManagementServiceRegisterOutwayRequest {
    body: ManagementRegisterOutwayRequest;
}

export interface ManagementServiceRejectIncomingAccessRequestRequest {
    serviceName: string;
    accessRequestId: string;
}

export interface ManagementServiceRevokeAccessGrantRequest {
    accessGrantId: string;
}

export interface ManagementServiceRevokeOutgoingOrderRequest {
    delegatee: string;
    reference: string;
}

export interface ManagementServiceSendAccessRequestRequest {
    organizationSerialNumber: string;
    serviceName: string;
    publicKeyPem?: string;
}

export interface ManagementServiceSynchronizeOutgoingAccessRequestsRequest {
    organizationSerialNumber: string;
    serviceName: string;
}

export interface ManagementServiceTerminateAccessProofRequest {
    organizationSerialNumber: string;
    serviceName: string;
    publicKeyFingerprint?: string;
}

export interface ManagementServiceUpdateInwayRequest {
    name: string;
    inway: ManagementInway;
}

export interface ManagementServiceUpdateOutgoingOrderRequest {
    body: ManagementUpdateOutgoingOrderRequest;
}

export interface ManagementServiceUpdateServiceOperationRequest {
    name: string;
    body: ManagementServiceUpdateServiceRequest;
}

export interface ManagementServiceUpdateSettingsRequest {
    body: ManagementUpdateSettingsRequest;
}

export interface ManagementServiceWithdrawOutgoingAccessRequestRequest {
    organizationSerialNumber: string;
    serviceName: string;
    publicKeyFingerprint?: string;
}

/**
 * 
 */
export class ManagementServiceApi extends runtime.BaseAPI {

    /**
     */
    async managementServiceAcceptTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/terms-of-service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceAcceptTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceAcceptTermsOfServiceRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceApproveIncomingAccessRequestRaw(requestParameters: ManagementServiceApproveIncomingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceApproveIncomingAccessRequest.');
        }

        if (requestParameters.accessRequestId === null || requestParameters.accessRequestId === undefined) {
            throw new runtime.RequiredError('accessRequestId','Required parameter requestParameters.accessRequestId was null or undefined when calling managementServiceApproveIncomingAccessRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/incoming/services/{service_name}/{access_request_id}/approve`.replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))).replace(`{${"access_request_id"}}`, encodeURIComponent(String(requestParameters.accessRequestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceApproveIncomingAccessRequest(requestParameters: ManagementServiceApproveIncomingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceApproveIncomingAccessRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceCreateOutgoingOrderRaw(requestParameters: ManagementServiceCreateOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceCreateOutgoingOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/outgoing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementCreateOutgoingOrderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceCreateOutgoingOrder(requestParameters: ManagementServiceCreateOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceCreateOutgoingOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceCreateServiceRaw(requestParameters: ManagementServiceCreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementCreateServiceResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceCreateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/services`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementCreateServiceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementCreateServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceCreateService(requestParameters: ManagementServiceCreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementCreateServiceResponse> {
        const response = await this.managementServiceCreateServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceDeleteInwayRaw(requestParameters: ManagementServiceDeleteInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceDeleteInway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/inways/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceDeleteInway(requestParameters: ManagementServiceDeleteInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceDeleteInwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceDeleteOutwayRaw(requestParameters: ManagementServiceDeleteOutwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceDeleteOutway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/outways/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceDeleteOutway(requestParameters: ManagementServiceDeleteOutwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceDeleteOutwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceDeleteServiceRaw(requestParameters: ManagementServiceDeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceDeleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/services/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceDeleteService(requestParameters: ManagementServiceDeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceDeleteServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceDownloadFinanceExportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementDownloadFinanceExportResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/finance/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementDownloadFinanceExportResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceDownloadFinanceExport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementDownloadFinanceExportResponse> {
        const response = await this.managementServiceDownloadFinanceExportRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceGetInwayRaw(requestParameters: ManagementServiceGetInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetInwayResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceGetInway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/inways/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetInwayResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceGetInway(requestParameters: ManagementServiceGetInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetInwayResponse> {
        const response = await this.managementServiceGetInwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceGetServiceRaw(requestParameters: ManagementServiceGetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetServiceResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceGetService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/services/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceGetService(requestParameters: ManagementServiceGetServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetServiceResponse> {
        const response = await this.managementServiceGetServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceGetSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetSettingsResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceGetSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetSettingsResponse> {
        const response = await this.managementServiceGetSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceGetStatisticsOfServicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetStatisticsOfServicesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/statistics/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetStatisticsOfServicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceGetStatisticsOfServices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetStatisticsOfServicesResponse> {
        const response = await this.managementServiceGetStatisticsOfServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceGetTermsOfServiceStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetTermsOfServiceStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/terms-of-service`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetTermsOfServiceStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceGetTermsOfServiceStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetTermsOfServiceStatusResponse> {
        const response = await this.managementServiceGetTermsOfServiceStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceIsFinanceEnabledRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementIsFinanceEnabledResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/finance/enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementIsFinanceEnabledResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceIsFinanceEnabled(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementIsFinanceEnabledResponse> {
        const response = await this.managementServiceIsFinanceEnabledRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceIsTXLogEnabledRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementIsTXLogEnabledResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/txlog/enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementIsTXLogEnabledResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceIsTXLogEnabled(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementIsTXLogEnabledResponse> {
        const response = await this.managementServiceIsTXLogEnabledRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListAccessGrantsForServiceRaw(requestParameters: ManagementServiceListAccessGrantsForServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListAccessGrantsForServiceResponse>> {
        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceListAccessGrantsForService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-grants/services/{service_name}`.replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListAccessGrantsForServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListAccessGrantsForService(requestParameters: ManagementServiceListAccessGrantsForServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListAccessGrantsForServiceResponse> {
        const response = await this.managementServiceListAccessGrantsForServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListAuditLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListAuditLogsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/audit-logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListAuditLogsResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListAuditLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListAuditLogsResponse> {
        const response = await this.managementServiceListAuditLogsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListIncomingAccessRequestsRaw(requestParameters: ManagementServiceListIncomingAccessRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListIncomingAccessRequestsResponse>> {
        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceListIncomingAccessRequests.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/incoming/services/{service_name}`.replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListIncomingAccessRequestsResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListIncomingAccessRequests(requestParameters: ManagementServiceListIncomingAccessRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListIncomingAccessRequestsResponse> {
        const response = await this.managementServiceListIncomingAccessRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListIncomingOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListIncomingOrdersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/incoming`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListIncomingOrdersResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListIncomingOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListIncomingOrdersResponse> {
        const response = await this.managementServiceListIncomingOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListInwaysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListInwaysResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/inways`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListInwaysResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListInways(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListInwaysResponse> {
        const response = await this.managementServiceListInwaysRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListOutgoingOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListOutgoingOrdersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/outgoing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListOutgoingOrdersResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListOutgoingOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListOutgoingOrdersResponse> {
        const response = await this.managementServiceListOutgoingOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListOutwaysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListOutwaysResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/outways`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListOutwaysResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListOutways(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListOutwaysResponse> {
        const response = await this.managementServiceListOutwaysRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceListServicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementListServicesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementListServicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceListServices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementListServicesResponse> {
        const response = await this.managementServiceListServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceRegisterInwayRaw(requestParameters: ManagementServiceRegisterInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementRegisterInwayResponse>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceRegisterInway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/inways`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementRegisterInwayRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementRegisterInwayResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceRegisterInway(requestParameters: ManagementServiceRegisterInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementRegisterInwayResponse> {
        const response = await this.managementServiceRegisterInwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceRegisterOutwayRaw(requestParameters: ManagementServiceRegisterOutwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceRegisterOutway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/outways`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementRegisterOutwayRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceRegisterOutway(requestParameters: ManagementServiceRegisterOutwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceRegisterOutwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceRejectIncomingAccessRequestRaw(requestParameters: ManagementServiceRejectIncomingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceRejectIncomingAccessRequest.');
        }

        if (requestParameters.accessRequestId === null || requestParameters.accessRequestId === undefined) {
            throw new runtime.RequiredError('accessRequestId','Required parameter requestParameters.accessRequestId was null or undefined when calling managementServiceRejectIncomingAccessRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/incoming/services/{service_name}/{access_request_id}/reject`.replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))).replace(`{${"access_request_id"}}`, encodeURIComponent(String(requestParameters.accessRequestId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceRejectIncomingAccessRequest(requestParameters: ManagementServiceRejectIncomingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceRejectIncomingAccessRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceRevokeAccessGrantRaw(requestParameters: ManagementServiceRevokeAccessGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementRevokeAccessGrantResponse>> {
        if (requestParameters.accessGrantId === null || requestParameters.accessGrantId === undefined) {
            throw new runtime.RequiredError('accessGrantId','Required parameter requestParameters.accessGrantId was null or undefined when calling managementServiceRevokeAccessGrant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-grants/{access_grant_id}/revoke`.replace(`{${"access_grant_id"}}`, encodeURIComponent(String(requestParameters.accessGrantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementRevokeAccessGrantResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceRevokeAccessGrant(requestParameters: ManagementServiceRevokeAccessGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementRevokeAccessGrantResponse> {
        const response = await this.managementServiceRevokeAccessGrantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceRevokeOutgoingOrderRaw(requestParameters: ManagementServiceRevokeOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.delegatee === null || requestParameters.delegatee === undefined) {
            throw new runtime.RequiredError('delegatee','Required parameter requestParameters.delegatee was null or undefined when calling managementServiceRevokeOutgoingOrder.');
        }

        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling managementServiceRevokeOutgoingOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/outgoing/{delegatee}/{reference}/revoke`.replace(`{${"delegatee"}}`, encodeURIComponent(String(requestParameters.delegatee))).replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceRevokeOutgoingOrder(requestParameters: ManagementServiceRevokeOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceRevokeOutgoingOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceSendAccessRequestRaw(requestParameters: ManagementServiceSendAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementSendAccessRequestResponse>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling managementServiceSendAccessRequest.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceSendAccessRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.publicKeyPem !== undefined) {
            queryParameters['public_key_pem'] = requestParameters.publicKeyPem;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/outgoing/{organization_serial_number}/services/{service_name}`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementSendAccessRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceSendAccessRequest(requestParameters: ManagementServiceSendAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementSendAccessRequestResponse> {
        const response = await this.managementServiceSendAccessRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceSynchronizeAllOutgoingAccessRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/outgoing/synchronize`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceSynchronizeAllOutgoingAccessRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceSynchronizeAllOutgoingAccessRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceSynchronizeOrdersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementSynchronizeOrdersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/orders/synchronize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementSynchronizeOrdersResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceSynchronizeOrders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementSynchronizeOrdersResponse> {
        const response = await this.managementServiceSynchronizeOrdersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceSynchronizeOutgoingAccessRequestsRaw(requestParameters: ManagementServiceSynchronizeOutgoingAccessRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling managementServiceSynchronizeOutgoingAccessRequests.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceSynchronizeOutgoingAccessRequests.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/outgoing/{organization_serial_number}/services/{service_name}/synchronize`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceSynchronizeOutgoingAccessRequests(requestParameters: ManagementServiceSynchronizeOutgoingAccessRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceSynchronizeOutgoingAccessRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceTerminateAccessProofRaw(requestParameters: ManagementServiceTerminateAccessProofRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling managementServiceTerminateAccessProof.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceTerminateAccessProof.');
        }

        const queryParameters: any = {};

        if (requestParameters.publicKeyFingerprint !== undefined) {
            queryParameters['public_key_fingerprint'] = requestParameters.publicKeyFingerprint;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-proofs/{organization_serial_number}/services/{service_name}/terminate`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceTerminateAccessProof(requestParameters: ManagementServiceTerminateAccessProofRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceTerminateAccessProofRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceUpdateInwayRaw(requestParameters: ManagementServiceUpdateInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementUpdateInwayResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceUpdateInway.');
        }

        if (requestParameters.inway === null || requestParameters.inway === undefined) {
            throw new runtime.RequiredError('inway','Required parameter requestParameters.inway was null or undefined when calling managementServiceUpdateInway.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/inways/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementInwayToJSON(requestParameters.inway),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementUpdateInwayResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceUpdateInway(requestParameters: ManagementServiceUpdateInwayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementUpdateInwayResponse> {
        const response = await this.managementServiceUpdateInwayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceUpdateOutgoingOrderRaw(requestParameters: ManagementServiceUpdateOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceUpdateOutgoingOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/orders/outgoing`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementUpdateOutgoingOrderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceUpdateOutgoingOrder(requestParameters: ManagementServiceUpdateOutgoingOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceUpdateOutgoingOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceUpdateServiceRaw(requestParameters: ManagementServiceUpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementUpdateServiceResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling managementServiceUpdateService.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceUpdateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/services/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementServiceUpdateServiceRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementUpdateServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementServiceUpdateService(requestParameters: ManagementServiceUpdateServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementUpdateServiceResponse> {
        const response = await this.managementServiceUpdateServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceUpdateSettingsRaw(requestParameters: ManagementServiceUpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling managementServiceUpdateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementUpdateSettingsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceUpdateSettings(requestParameters: ManagementServiceUpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceUpdateSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async managementServiceWithdrawOutgoingAccessRequestRaw(requestParameters: ManagementServiceWithdrawOutgoingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling managementServiceWithdrawOutgoingAccessRequest.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling managementServiceWithdrawOutgoingAccessRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.publicKeyFingerprint !== undefined) {
            queryParameters['public_key_fingerprint'] = requestParameters.publicKeyFingerprint;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/access-requests/outgoing/{organization_serial_number}/services/{service_name}/cancel`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async managementServiceWithdrawOutgoingAccessRequest(requestParameters: ManagementServiceWithdrawOutgoingAccessRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.managementServiceWithdrawOutgoingAccessRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
