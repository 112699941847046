/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NlxmanagementAccessGrant } from './NlxmanagementAccessGrant';
import {
    NlxmanagementAccessGrantFromJSON,
    NlxmanagementAccessGrantFromJSONTyped,
    NlxmanagementAccessGrantToJSON,
} from './NlxmanagementAccessGrant';

/**
 * 
 * @export
 * @interface ManagementRevokeAccessGrantResponse
 */
export interface ManagementRevokeAccessGrantResponse {
    /**
     * 
     * @type {NlxmanagementAccessGrant}
     * @memberof ManagementRevokeAccessGrantResponse
     */
    accessGrant?: NlxmanagementAccessGrant;
}

/**
 * Check if a given object implements the ManagementRevokeAccessGrantResponse interface.
 */
export function instanceOfManagementRevokeAccessGrantResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementRevokeAccessGrantResponseFromJSON(json: any): ManagementRevokeAccessGrantResponse {
    return ManagementRevokeAccessGrantResponseFromJSONTyped(json, false);
}

export function ManagementRevokeAccessGrantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementRevokeAccessGrantResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessGrant': !exists(json, 'access_grant') ? undefined : NlxmanagementAccessGrantFromJSON(json['access_grant']),
    };
}

export function ManagementRevokeAccessGrantResponseToJSON(value?: ManagementRevokeAccessGrantResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_grant': NlxmanagementAccessGrantToJSON(value.accessGrant),
    };
}

