/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalOrganization } from './ExternalOrganization';
import {
    ExternalOrganizationFromJSON,
    ExternalOrganizationFromJSONTyped,
    ExternalOrganizationToJSON,
} from './ExternalOrganization';

/**
 * 
 * @export
 * @interface ManagementListServicesResponseServiceAuthorizationSettingsAuthorization
 */
export interface ManagementListServicesResponseServiceAuthorizationSettingsAuthorization {
    /**
     * 
     * @type {ExternalOrganization}
     * @memberof ManagementListServicesResponseServiceAuthorizationSettingsAuthorization
     */
    organization?: ExternalOrganization;
    /**
     * 
     * @type {string}
     * @memberof ManagementListServicesResponseServiceAuthorizationSettingsAuthorization
     */
    publicKeyHash?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementListServicesResponseServiceAuthorizationSettingsAuthorization
     */
    publicKeyPem?: string;
}

/**
 * Check if a given object implements the ManagementListServicesResponseServiceAuthorizationSettingsAuthorization interface.
 */
export function instanceOfManagementListServicesResponseServiceAuthorizationSettingsAuthorization(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementListServicesResponseServiceAuthorizationSettingsAuthorizationFromJSON(json: any): ManagementListServicesResponseServiceAuthorizationSettingsAuthorization {
    return ManagementListServicesResponseServiceAuthorizationSettingsAuthorizationFromJSONTyped(json, false);
}

export function ManagementListServicesResponseServiceAuthorizationSettingsAuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementListServicesResponseServiceAuthorizationSettingsAuthorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': !exists(json, 'organization') ? undefined : ExternalOrganizationFromJSON(json['organization']),
        'publicKeyHash': !exists(json, 'public_key_hash') ? undefined : json['public_key_hash'],
        'publicKeyPem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
    };
}

export function ManagementListServicesResponseServiceAuthorizationSettingsAuthorizationToJSON(value?: ManagementListServicesResponseServiceAuthorizationSettingsAuthorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': ExternalOrganizationToJSON(value.organization),
        'public_key_hash': value.publicKeyHash,
        'public_key_pem': value.publicKeyPem,
    };
}

