/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementSettings
 */
export interface ManagementSettings {
    /**
     * 
     * @type {string}
     * @memberof ManagementSettings
     */
    organizationInway?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementSettings
     */
    organizationEmailAddress?: string;
}

/**
 * Check if a given object implements the ManagementSettings interface.
 */
export function instanceOfManagementSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementSettingsFromJSON(json: any): ManagementSettings {
    return ManagementSettingsFromJSONTyped(json, false);
}

export function ManagementSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationInway': !exists(json, 'organization_inway') ? undefined : json['organization_inway'],
        'organizationEmailAddress': !exists(json, 'organization_email_address') ? undefined : json['organization_email_address'],
    };
}

export function ManagementSettingsToJSON(value?: ManagementSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_inway': value.organizationInway,
        'organization_email_address': value.organizationEmailAddress,
    };
}

