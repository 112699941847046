/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementTXLogDirection } from './ManagementTXLogDirection';
import {
    ManagementTXLogDirectionFromJSON,
    ManagementTXLogDirectionFromJSONTyped,
    ManagementTXLogDirectionToJSON,
} from './ManagementTXLogDirection';
import type { ManagementTXLogNlxService } from './ManagementTXLogNlxService';
import {
    ManagementTXLogNlxServiceFromJSON,
    ManagementTXLogNlxServiceFromJSONTyped,
    ManagementTXLogNlxServiceToJSON,
} from './ManagementTXLogNlxService';
import type { ManagementTXLogOrder } from './ManagementTXLogOrder';
import {
    ManagementTXLogOrderFromJSON,
    ManagementTXLogOrderFromJSONTyped,
    ManagementTXLogOrderToJSON,
} from './ManagementTXLogOrder';
import type { ManagementTXLogOrganization } from './ManagementTXLogOrganization';
import {
    ManagementTXLogOrganizationFromJSON,
    ManagementTXLogOrganizationFromJSONTyped,
    ManagementTXLogOrganizationToJSON,
} from './ManagementTXLogOrganization';

/**
 * 
 * @export
 * @interface ManagementTXLogRecord
 */
export interface ManagementTXLogRecord {
    /**
     * 
     * @type {ManagementTXLogOrganization}
     * @memberof ManagementTXLogRecord
     */
    source?: ManagementTXLogOrganization;
    /**
     * 
     * @type {ManagementTXLogOrganization}
     * @memberof ManagementTXLogRecord
     */
    destination?: ManagementTXLogOrganization;
    /**
     * 
     * @type {ManagementTXLogDirection}
     * @memberof ManagementTXLogRecord
     */
    direction?: ManagementTXLogDirection;
    /**
     * 
     * @type {ManagementTXLogNlxService}
     * @memberof ManagementTXLogRecord
     */
    service?: ManagementTXLogNlxService;
    /**
     * 
     * @type {string}
     * @memberof ManagementTXLogRecord
     */
    data?: string;
    /**
     * 
     * @type {ManagementTXLogOrder}
     * @memberof ManagementTXLogRecord
     */
    order?: ManagementTXLogOrder;
    /**
     * 
     * @type {string}
     * @memberof ManagementTXLogRecord
     */
    transactionId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ManagementTXLogRecord
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the ManagementTXLogRecord interface.
 */
export function instanceOfManagementTXLogRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementTXLogRecordFromJSON(json: any): ManagementTXLogRecord {
    return ManagementTXLogRecordFromJSONTyped(json, false);
}

export function ManagementTXLogRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementTXLogRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': !exists(json, 'source') ? undefined : ManagementTXLogOrganizationFromJSON(json['source']),
        'destination': !exists(json, 'destination') ? undefined : ManagementTXLogOrganizationFromJSON(json['destination']),
        'direction': !exists(json, 'direction') ? undefined : ManagementTXLogDirectionFromJSON(json['direction']),
        'service': !exists(json, 'service') ? undefined : ManagementTXLogNlxServiceFromJSON(json['service']),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'order': !exists(json, 'order') ? undefined : ManagementTXLogOrderFromJSON(json['order']),
        'transactionId': !exists(json, 'transaction_id') ? undefined : json['transaction_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function ManagementTXLogRecordToJSON(value?: ManagementTXLogRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': ManagementTXLogOrganizationToJSON(value.source),
        'destination': ManagementTXLogOrganizationToJSON(value.destination),
        'direction': ManagementTXLogDirectionToJSON(value.direction),
        'service': ManagementTXLogNlxServiceToJSON(value.service),
        'data': value.data,
        'order': ManagementTXLogOrderToJSON(value.order),
        'transaction_id': value.transactionId,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

