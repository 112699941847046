/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ManagementTXLogDirection = {
    UNSPECIFIED: 'TX_LOG_DIRECTION_UNSPECIFIED',
    IN: 'TX_LOG_DIRECTION_IN',
    OUT: 'TX_LOG_DIRECTION_OUT'
} as const;
export type ManagementTXLogDirection = typeof ManagementTXLogDirection[keyof typeof ManagementTXLogDirection];


export function ManagementTXLogDirectionFromJSON(json: any): ManagementTXLogDirection {
    return ManagementTXLogDirectionFromJSONTyped(json, false);
}

export function ManagementTXLogDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementTXLogDirection {
    return json as ManagementTXLogDirection;
}

export function ManagementTXLogDirectionToJSON(value?: ManagementTXLogDirection | null): any {
    return value as any;
}

