/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalOrganization } from './ExternalOrganization';
import {
    ExternalOrganizationFromJSON,
    ExternalOrganizationFromJSONTyped,
    ExternalOrganizationToJSON,
} from './ExternalOrganization';

/**
 * 
 * @export
 * @interface ManagementAuditLogRecordMetadata
 */
export interface ManagementAuditLogRecordMetadata {
    /**
     * 
     * @type {ExternalOrganization}
     * @memberof ManagementAuditLogRecordMetadata
     */
    delegatee?: ExternalOrganization;
    /**
     * 
     * @type {ExternalOrganization}
     * @memberof ManagementAuditLogRecordMetadata
     */
    delegator?: ExternalOrganization;
    /**
     * 
     * @type {string}
     * @memberof ManagementAuditLogRecordMetadata
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementAuditLogRecordMetadata
     */
    inwayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementAuditLogRecordMetadata
     */
    outwayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementAuditLogRecordMetadata
     */
    publicKeyFingerprint?: string;
}

/**
 * Check if a given object implements the ManagementAuditLogRecordMetadata interface.
 */
export function instanceOfManagementAuditLogRecordMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementAuditLogRecordMetadataFromJSON(json: any): ManagementAuditLogRecordMetadata {
    return ManagementAuditLogRecordMetadataFromJSONTyped(json, false);
}

export function ManagementAuditLogRecordMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementAuditLogRecordMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delegatee': !exists(json, 'delegatee') ? undefined : ExternalOrganizationFromJSON(json['delegatee']),
        'delegator': !exists(json, 'delegator') ? undefined : ExternalOrganizationFromJSON(json['delegator']),
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'inwayName': !exists(json, 'inway_name') ? undefined : json['inway_name'],
        'outwayName': !exists(json, 'outway_name') ? undefined : json['outway_name'],
        'publicKeyFingerprint': !exists(json, 'public_key_fingerprint') ? undefined : json['public_key_fingerprint'],
    };
}

export function ManagementAuditLogRecordMetadataToJSON(value?: ManagementAuditLogRecordMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delegatee': ExternalOrganizationToJSON(value.delegatee),
        'delegator': ExternalOrganizationToJSON(value.delegator),
        'reference': value.reference,
        'inway_name': value.inwayName,
        'outway_name': value.outwayName,
        'public_key_fingerprint': value.publicKeyFingerprint,
    };
}

