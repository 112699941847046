/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementOutway
 */
export interface ManagementOutway {
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    publicKeyPem?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    publicKeyFingerprint?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutway
     */
    selfAddressApi?: string;
}

/**
 * Check if a given object implements the ManagementOutway interface.
 */
export function instanceOfManagementOutway(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementOutwayFromJSON(json: any): ManagementOutway {
    return ManagementOutwayFromJSONTyped(json, false);
}

export function ManagementOutwayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementOutway {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ipAddress': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'publicKeyPem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'publicKeyFingerprint': !exists(json, 'public_key_fingerprint') ? undefined : json['public_key_fingerprint'],
        'selfAddressApi': !exists(json, 'self_address_api') ? undefined : json['self_address_api'],
    };
}

export function ManagementOutwayToJSON(value?: ManagementOutway | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'ip_address': value.ipAddress,
        'public_key_pem': value.publicKeyPem,
        'version': value.version,
        'public_key_fingerprint': value.publicKeyFingerprint,
        'self_address_api': value.selfAddressApi,
    };
}

