/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExternalAccessRequestState = {
    UNSPECIFIED: 'ACCESS_REQUEST_STATE_UNSPECIFIED',
    FAILED: 'ACCESS_REQUEST_STATE_FAILED',
    RECEIVED: 'ACCESS_REQUEST_STATE_RECEIVED',
    APPROVED: 'ACCESS_REQUEST_STATE_APPROVED',
    REJECTED: 'ACCESS_REQUEST_STATE_REJECTED',
    WITHDRAWN: 'ACCESS_REQUEST_STATE_WITHDRAWN'
} as const;
export type ExternalAccessRequestState = typeof ExternalAccessRequestState[keyof typeof ExternalAccessRequestState];


export function ExternalAccessRequestStateFromJSON(json: any): ExternalAccessRequestState {
    return ExternalAccessRequestStateFromJSONTyped(json, false);
}

export function ExternalAccessRequestStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAccessRequestState {
    return json as ExternalAccessRequestState;
}

export function ExternalAccessRequestStateToJSON(value?: ExternalAccessRequestState | null): any {
    return value as any;
}

