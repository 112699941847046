/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementCreateOutgoingOrderRequest
 */
export interface ManagementCreateOutgoingOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    publicKeyPem?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    delegatee?: string;
    /**
     * 
     * @type {Date}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    validUntil?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ManagementCreateOutgoingOrderRequest
     */
    accessProofIds?: Array<string>;
}

/**
 * Check if a given object implements the ManagementCreateOutgoingOrderRequest interface.
 */
export function instanceOfManagementCreateOutgoingOrderRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementCreateOutgoingOrderRequestFromJSON(json: any): ManagementCreateOutgoingOrderRequest {
    return ManagementCreateOutgoingOrderRequestFromJSONTyped(json, false);
}

export function ManagementCreateOutgoingOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementCreateOutgoingOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicKeyPem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
        'delegatee': !exists(json, 'delegatee') ? undefined : json['delegatee'],
        'validFrom': !exists(json, 'valid_from') ? undefined : (new Date(json['valid_from'])),
        'validUntil': !exists(json, 'valid_until') ? undefined : (new Date(json['valid_until'])),
        'accessProofIds': !exists(json, 'access_proof_ids') ? undefined : json['access_proof_ids'],
    };
}

export function ManagementCreateOutgoingOrderRequestToJSON(value?: ManagementCreateOutgoingOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'description': value.description,
        'public_key_pem': value.publicKeyPem,
        'delegatee': value.delegatee,
        'valid_from': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'valid_until': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'access_proof_ids': value.accessProofIds,
    };
}

