/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ManagementErrorCode = {
    UNSPECIFIED: 'ERROR_CODE_UNSPECIFIED',
    INTERNAL: 'ERROR_CODE_INTERNAL',
    NO_INWAY_SELECTED: 'ERROR_CODE_NO_INWAY_SELECTED'
} as const;
export type ManagementErrorCode = typeof ManagementErrorCode[keyof typeof ManagementErrorCode];


export function ManagementErrorCodeFromJSON(json: any): ManagementErrorCode {
    return ManagementErrorCodeFromJSONTyped(json, false);
}

export function ManagementErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementErrorCode {
    return json as ManagementErrorCode;
}

export function ManagementErrorCodeToJSON(value?: ManagementErrorCode | null): any {
    return value as any;
}

