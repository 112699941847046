// Copyright © VNG Realisatie 2020
// Licensed under the EUPL
//

import styled from 'styled-components'

export const StyledWrapper = styled.span`
  display: flex;
  align-items: center;
`
