/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagementUpdateSettingsRequest
 */
export interface ManagementUpdateSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagementUpdateSettingsRequest
     */
    organizationInway?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementUpdateSettingsRequest
     */
    organizationEmailAddress?: string;
}

/**
 * Check if a given object implements the ManagementUpdateSettingsRequest interface.
 */
export function instanceOfManagementUpdateSettingsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementUpdateSettingsRequestFromJSON(json: any): ManagementUpdateSettingsRequest {
    return ManagementUpdateSettingsRequestFromJSONTyped(json, false);
}

export function ManagementUpdateSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementUpdateSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationInway': !exists(json, 'organization_inway') ? undefined : json['organization_inway'],
        'organizationEmailAddress': !exists(json, 'organization_email_address') ? undefined : json['organization_email_address'],
    };
}

export function ManagementUpdateSettingsRequestToJSON(value?: ManagementUpdateSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization_inway': value.organizationInway,
        'organization_email_address': value.organizationEmailAddress,
    };
}

