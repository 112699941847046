// Copyright © VNG Realisatie 2020
// Licensed under the EUPL
//

import styled from 'styled-components'
import Table from '../../../../../../../components/Table'

export const TdActions = styled(Table.Td)`
  text-align: right;
  vertical-align: top;
  width: 140px;
`
