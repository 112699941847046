/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManagementDirectoryServiceListServicesResponse,
  ManagementGetOrganizationServiceResponse,
  ManagementGetTermsOfServiceResponse,
  ManagementRequestAccessToServiceResponse,
  RpcStatus,
} from '../models';
import {
    ManagementDirectoryServiceListServicesResponseFromJSON,
    ManagementDirectoryServiceListServicesResponseToJSON,
    ManagementGetOrganizationServiceResponseFromJSON,
    ManagementGetOrganizationServiceResponseToJSON,
    ManagementGetTermsOfServiceResponseFromJSON,
    ManagementGetTermsOfServiceResponseToJSON,
    ManagementRequestAccessToServiceResponseFromJSON,
    ManagementRequestAccessToServiceResponseToJSON,
    RpcStatusFromJSON,
    RpcStatusToJSON,
} from '../models';

export interface DirectoryServiceGetOrganizationServiceRequest {
    organizationSerialNumber: string;
    serviceName: string;
}

export interface DirectoryServiceRequestAccessToServiceRequest {
    organizationSerialNumber: string;
    serviceName: string;
}

/**
 * 
 */
export class DirectoryServiceApi extends runtime.BaseAPI {

    /**
     */
    async directoryServiceGetOrganizationServiceRaw(requestParameters: DirectoryServiceGetOrganizationServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetOrganizationServiceResponse>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling directoryServiceGetOrganizationService.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling directoryServiceGetOrganizationService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/directory/organizations/{organization_serial_number}/services/{service_name}`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetOrganizationServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async directoryServiceGetOrganizationService(requestParameters: DirectoryServiceGetOrganizationServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetOrganizationServiceResponse> {
        const response = await this.directoryServiceGetOrganizationServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directoryServiceGetTermsOfServiceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementGetTermsOfServiceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/directory/terms-of-service`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementGetTermsOfServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async directoryServiceGetTermsOfService(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementGetTermsOfServiceResponse> {
        const response = await this.directoryServiceGetTermsOfServiceRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async directoryServiceListServicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementDirectoryServiceListServicesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/directory/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementDirectoryServiceListServicesResponseFromJSON(jsonValue));
    }

    /**
     */
    async directoryServiceListServices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementDirectoryServiceListServicesResponse> {
        const response = await this.directoryServiceListServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async directoryServiceRequestAccessToServiceRaw(requestParameters: DirectoryServiceRequestAccessToServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementRequestAccessToServiceResponse>> {
        if (requestParameters.organizationSerialNumber === null || requestParameters.organizationSerialNumber === undefined) {
            throw new runtime.RequiredError('organizationSerialNumber','Required parameter requestParameters.organizationSerialNumber was null or undefined when calling directoryServiceRequestAccessToService.');
        }

        if (requestParameters.serviceName === null || requestParameters.serviceName === undefined) {
            throw new runtime.RequiredError('serviceName','Required parameter requestParameters.serviceName was null or undefined when calling directoryServiceRequestAccessToService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/directory/organizations/{organization_serial_number}/services/{service_name}/access-requests`.replace(`{${"organization_serial_number"}}`, encodeURIComponent(String(requestParameters.organizationSerialNumber))).replace(`{${"service_name"}}`, encodeURIComponent(String(requestParameters.serviceName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementRequestAccessToServiceResponseFromJSON(jsonValue));
    }

    /**
     */
    async directoryServiceRequestAccessToService(requestParameters: DirectoryServiceRequestAccessToServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementRequestAccessToServiceResponse> {
        const response = await this.directoryServiceRequestAccessToServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
