/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementOutgoingOrder } from './ManagementOutgoingOrder';
import {
    ManagementOutgoingOrderFromJSON,
    ManagementOutgoingOrderFromJSONTyped,
    ManagementOutgoingOrderToJSON,
} from './ManagementOutgoingOrder';

/**
 * 
 * @export
 * @interface ManagementListOutgoingOrdersResponse
 */
export interface ManagementListOutgoingOrdersResponse {
    /**
     * 
     * @type {Array<ManagementOutgoingOrder>}
     * @memberof ManagementListOutgoingOrdersResponse
     */
    orders?: Array<ManagementOutgoingOrder>;
}

/**
 * Check if a given object implements the ManagementListOutgoingOrdersResponse interface.
 */
export function instanceOfManagementListOutgoingOrdersResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementListOutgoingOrdersResponseFromJSON(json: any): ManagementListOutgoingOrdersResponse {
    return ManagementListOutgoingOrdersResponseFromJSONTyped(json, false);
}

export function ManagementListOutgoingOrdersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementListOutgoingOrdersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(ManagementOutgoingOrderFromJSON)),
    };
}

export function ManagementListOutgoingOrdersResponseToJSON(value?: ManagementListOutgoingOrdersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(ManagementOutgoingOrderToJSON)),
    };
}

