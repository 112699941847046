/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementAccessProof } from './ManagementAccessProof';
import {
    ManagementAccessProofFromJSON,
    ManagementAccessProofFromJSONTyped,
    ManagementAccessProofToJSON,
} from './ManagementAccessProof';
import type { ManagementOutgoingAccessRequest } from './ManagementOutgoingAccessRequest';
import {
    ManagementOutgoingAccessRequestFromJSON,
    ManagementOutgoingAccessRequestFromJSONTyped,
    ManagementOutgoingAccessRequestToJSON,
} from './ManagementOutgoingAccessRequest';

/**
 * 
 * @export
 * @interface DirectoryNlxServiceAccessState
 */
export interface DirectoryNlxServiceAccessState {
    /**
     * 
     * @type {ManagementOutgoingAccessRequest}
     * @memberof DirectoryNlxServiceAccessState
     */
    accessRequest?: ManagementOutgoingAccessRequest;
    /**
     * 
     * @type {ManagementAccessProof}
     * @memberof DirectoryNlxServiceAccessState
     */
    accessProof?: ManagementAccessProof;
}

/**
 * Check if a given object implements the DirectoryNlxServiceAccessState interface.
 */
export function instanceOfDirectoryNlxServiceAccessState(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DirectoryNlxServiceAccessStateFromJSON(json: any): DirectoryNlxServiceAccessState {
    return DirectoryNlxServiceAccessStateFromJSONTyped(json, false);
}

export function DirectoryNlxServiceAccessStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectoryNlxServiceAccessState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessRequest': !exists(json, 'access_request') ? undefined : ManagementOutgoingAccessRequestFromJSON(json['access_request']),
        'accessProof': !exists(json, 'access_proof') ? undefined : ManagementAccessProofFromJSON(json['access_proof']),
    };
}

export function DirectoryNlxServiceAccessStateToJSON(value?: DirectoryNlxServiceAccessState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_request': ManagementOutgoingAccessRequestToJSON(value.accessRequest),
        'access_proof': ManagementAccessProofToJSON(value.accessProof),
    };
}

