/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DirectoryNlxServiceState = {
    UNSPECIFIED: 'STATE_UNSPECIFIED',
    UP: 'STATE_UP',
    DOWN: 'STATE_DOWN',
    DEGRADED: 'STATE_DEGRADED'
} as const;
export type DirectoryNlxServiceState = typeof DirectoryNlxServiceState[keyof typeof DirectoryNlxServiceState];


export function DirectoryNlxServiceStateFromJSON(json: any): DirectoryNlxServiceState {
    return DirectoryNlxServiceStateFromJSONTyped(json, false);
}

export function DirectoryNlxServiceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DirectoryNlxServiceState {
    return json as DirectoryNlxServiceState;
}

export function DirectoryNlxServiceStateToJSON(value?: DirectoryNlxServiceState | null): any {
    return value as any;
}

