/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalOrganization } from './ExternalOrganization';
import {
    ExternalOrganizationFromJSON,
    ExternalOrganizationFromJSONTyped,
    ExternalOrganizationToJSON,
} from './ExternalOrganization';
import type { ManagementAccessProof } from './ManagementAccessProof';
import {
    ManagementAccessProofFromJSON,
    ManagementAccessProofFromJSONTyped,
    ManagementAccessProofToJSON,
} from './ManagementAccessProof';

/**
 * 
 * @export
 * @interface ManagementOutgoingOrder
 */
export interface ManagementOutgoingOrder {
    /**
     * 
     * @type {string}
     * @memberof ManagementOutgoingOrder
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutgoingOrder
     */
    description?: string;
    /**
     * 
     * @type {ExternalOrganization}
     * @memberof ManagementOutgoingOrder
     */
    delegatee?: ExternalOrganization;
    /**
     * 
     * @type {Date}
     * @memberof ManagementOutgoingOrder
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ManagementOutgoingOrder
     */
    validUntil?: Date;
    /**
     * 
     * @type {Array<ManagementAccessProof>}
     * @memberof ManagementOutgoingOrder
     */
    accessProofs?: Array<ManagementAccessProof>;
    /**
     * 
     * @type {Date}
     * @memberof ManagementOutgoingOrder
     */
    revokedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ManagementOutgoingOrder
     */
    publicKeyPem?: string;
}

/**
 * Check if a given object implements the ManagementOutgoingOrder interface.
 */
export function instanceOfManagementOutgoingOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementOutgoingOrderFromJSON(json: any): ManagementOutgoingOrder {
    return ManagementOutgoingOrderFromJSONTyped(json, false);
}

export function ManagementOutgoingOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementOutgoingOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'delegatee': !exists(json, 'delegatee') ? undefined : ExternalOrganizationFromJSON(json['delegatee']),
        'validFrom': !exists(json, 'valid_from') ? undefined : (new Date(json['valid_from'])),
        'validUntil': !exists(json, 'valid_until') ? undefined : (new Date(json['valid_until'])),
        'accessProofs': !exists(json, 'access_proofs') ? undefined : ((json['access_proofs'] as Array<any>).map(ManagementAccessProofFromJSON)),
        'revokedAt': !exists(json, 'revoked_at') ? undefined : (new Date(json['revoked_at'])),
        'publicKeyPem': !exists(json, 'public_key_pem') ? undefined : json['public_key_pem'],
    };
}

export function ManagementOutgoingOrderToJSON(value?: ManagementOutgoingOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reference': value.reference,
        'description': value.description,
        'delegatee': ExternalOrganizationToJSON(value.delegatee),
        'valid_from': value.validFrom === undefined ? undefined : (value.validFrom.toISOString()),
        'valid_until': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
        'access_proofs': value.accessProofs === undefined ? undefined : ((value.accessProofs as Array<any>).map(ManagementAccessProofToJSON)),
        'revoked_at': value.revokedAt === undefined ? undefined : (value.revokedAt.toISOString()),
        'public_key_pem': value.publicKeyPem,
    };
}

