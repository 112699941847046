/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalIncomingOrder } from './ExternalIncomingOrder';
import {
    ExternalIncomingOrderFromJSON,
    ExternalIncomingOrderFromJSONTyped,
    ExternalIncomingOrderToJSON,
} from './ExternalIncomingOrder';

/**
 * 
 * @export
 * @interface ManagementSynchronizeOrdersResponse
 */
export interface ManagementSynchronizeOrdersResponse {
    /**
     * 
     * @type {Array<ExternalIncomingOrder>}
     * @memberof ManagementSynchronizeOrdersResponse
     */
    orders?: Array<ExternalIncomingOrder>;
}

/**
 * Check if a given object implements the ManagementSynchronizeOrdersResponse interface.
 */
export function instanceOfManagementSynchronizeOrdersResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementSynchronizeOrdersResponseFromJSON(json: any): ManagementSynchronizeOrdersResponse {
    return ManagementSynchronizeOrdersResponseFromJSONTyped(json, false);
}

export function ManagementSynchronizeOrdersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementSynchronizeOrdersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(ExternalIncomingOrderFromJSON)),
    };
}

export function ManagementSynchronizeOrdersResponseToJSON(value?: ManagementSynchronizeOrdersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(ExternalIncomingOrderToJSON)),
    };
}

