/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DirectoryNlxServiceAccessState } from './DirectoryNlxServiceAccessState';
import {
    DirectoryNlxServiceAccessStateFromJSON,
    DirectoryNlxServiceAccessStateFromJSONTyped,
    DirectoryNlxServiceAccessStateToJSON,
} from './DirectoryNlxServiceAccessState';
import type { DirectoryNlxServiceState } from './DirectoryNlxServiceState';
import {
    DirectoryNlxServiceStateFromJSON,
    DirectoryNlxServiceStateFromJSONTyped,
    DirectoryNlxServiceStateToJSON,
} from './DirectoryNlxServiceState';
import type { ExternalOrganization } from './ExternalOrganization';
import {
    ExternalOrganizationFromJSON,
    ExternalOrganizationFromJSONTyped,
    ExternalOrganizationToJSON,
} from './ExternalOrganization';

/**
 * 
 * @export
 * @interface ManagementDirectoryNlxService
 */
export interface ManagementDirectoryNlxService {
    /**
     * 
     * @type {string}
     * @memberof ManagementDirectoryNlxService
     */
    serviceName?: string;
    /**
     * 
     * @type {ExternalOrganization}
     * @memberof ManagementDirectoryNlxService
     */
    organization?: ExternalOrganization;
    /**
     * 
     * @type {string}
     * @memberof ManagementDirectoryNlxService
     */
    apiSpecificationType?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementDirectoryNlxService
     */
    documentationUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementDirectoryNlxService
     */
    publicSupportContact?: string;
    /**
     * 
     * @type {DirectoryNlxServiceState}
     * @memberof ManagementDirectoryNlxService
     */
    state?: DirectoryNlxServiceState;
    /**
     * 
     * @type {number}
     * @memberof ManagementDirectoryNlxService
     */
    oneTimeCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagementDirectoryNlxService
     */
    monthlyCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagementDirectoryNlxService
     */
    requestCosts?: number;
    /**
     * 
     * @type {Array<DirectoryNlxServiceAccessState>}
     * @memberof ManagementDirectoryNlxService
     */
    accessStates?: Array<DirectoryNlxServiceAccessState>;
}

/**
 * Check if a given object implements the ManagementDirectoryNlxService interface.
 */
export function instanceOfManagementDirectoryNlxService(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementDirectoryNlxServiceFromJSON(json: any): ManagementDirectoryNlxService {
    return ManagementDirectoryNlxServiceFromJSONTyped(json, false);
}

export function ManagementDirectoryNlxServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementDirectoryNlxService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceName': !exists(json, 'service_name') ? undefined : json['service_name'],
        'organization': !exists(json, 'organization') ? undefined : ExternalOrganizationFromJSON(json['organization']),
        'apiSpecificationType': !exists(json, 'api_specification_type') ? undefined : json['api_specification_type'],
        'documentationUrl': !exists(json, 'documentation_url') ? undefined : json['documentation_url'],
        'publicSupportContact': !exists(json, 'public_support_contact') ? undefined : json['public_support_contact'],
        'state': !exists(json, 'state') ? undefined : DirectoryNlxServiceStateFromJSON(json['state']),
        'oneTimeCosts': !exists(json, 'one_time_costs') ? undefined : json['one_time_costs'],
        'monthlyCosts': !exists(json, 'monthly_costs') ? undefined : json['monthly_costs'],
        'requestCosts': !exists(json, 'request_costs') ? undefined : json['request_costs'],
        'accessStates': !exists(json, 'access_states') ? undefined : ((json['access_states'] as Array<any>).map(DirectoryNlxServiceAccessStateFromJSON)),
    };
}

export function ManagementDirectoryNlxServiceToJSON(value?: ManagementDirectoryNlxService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service_name': value.serviceName,
        'organization': ExternalOrganizationToJSON(value.organization),
        'api_specification_type': value.apiSpecificationType,
        'documentation_url': value.documentationUrl,
        'public_support_contact': value.publicSupportContact,
        'state': DirectoryNlxServiceStateToJSON(value.state),
        'one_time_costs': value.oneTimeCosts,
        'monthly_costs': value.monthlyCosts,
        'request_costs': value.requestCosts,
        'access_states': value.accessStates === undefined ? undefined : ((value.accessStates as Array<any>).map(DirectoryNlxServiceAccessStateToJSON)),
    };
}

