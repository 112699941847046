/* tslint:disable */
/* eslint-disable */
/**
 * management.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ManagementOutway } from './ManagementOutway';
import {
    ManagementOutwayFromJSON,
    ManagementOutwayFromJSONTyped,
    ManagementOutwayToJSON,
} from './ManagementOutway';

/**
 * 
 * @export
 * @interface ManagementListOutwaysResponse
 */
export interface ManagementListOutwaysResponse {
    /**
     * 
     * @type {Array<ManagementOutway>}
     * @memberof ManagementListOutwaysResponse
     */
    outways?: Array<ManagementOutway>;
}

/**
 * Check if a given object implements the ManagementListOutwaysResponse interface.
 */
export function instanceOfManagementListOutwaysResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ManagementListOutwaysResponseFromJSON(json: any): ManagementListOutwaysResponse {
    return ManagementListOutwaysResponseFromJSONTyped(json, false);
}

export function ManagementListOutwaysResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagementListOutwaysResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outways': !exists(json, 'outways') ? undefined : ((json['outways'] as Array<any>).map(ManagementOutwayFromJSON)),
    };
}

export function ManagementListOutwaysResponseToJSON(value?: ManagementListOutwaysResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outways': value.outways === undefined ? undefined : ((value.outways as Array<any>).map(ManagementOutwayToJSON)),
    };
}

